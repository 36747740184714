import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/users/types/user.interface';
import { AuthenticationResponse } from '../../types/authentication-response.interface';
import { Credentials } from '../../types/credentials.interface';
import {
    logIn,
    logInFail,
    logInSuccess,
    logOut,
    requestResetPassword,
    requestResetPasswordFail,
    requestResetPasswordSuccess,
    resetPassword,
    resetPasswordFail,
    resetPasswordSuccess,
} from '../actions/authentication.actions';

export interface AuthenticationState {
    user: User;
    userLoggedIn: boolean;
    userLoggingIn: boolean;
    token: string;
    loginError: Error;

    requestResetPasswordSending: boolean;
    requestResetPasswordSent: boolean;
    requestResetPasswordError: Error;

    resettingPassword: boolean;
    resetPasswordError: Error;
}

const initialState: AuthenticationState = {
    user: null,
    userLoggedIn: false,
    userLoggingIn: false,
    token: null,
    loginError: null,
    requestResetPasswordError: undefined,
    requestResetPasswordSending: false,
    requestResetPasswordSent: false,
    resetPasswordError: undefined,
    resettingPassword: false,
};

const logInReducer = (state: AuthenticationState, props: Credentials): AuthenticationState => ({
    ...state,
    userLoggedIn: false,
    userLoggingIn: true,
});

const logInFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    userLoggedIn: false,
    userLoggingIn: false,
    loginError: props,
});

const logInSuccessReducer = (state: AuthenticationState, props: AuthenticationResponse): AuthenticationState => ({
    ...state,
    userLoggedIn: true,
    userLoggingIn: false,
    user: props.user,
    token: props.token,
});

const logOutReducer = (state: AuthenticationState, props): AuthenticationState => ({
    ...state,
    user: undefined,
    userLoggedIn: false,
    token: undefined,
});

const requestResetPasswordReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    requestResetPasswordSent: false,
    requestResetPasswordSending: true,
    requestResetPasswordError: undefined,
});

const requestResetPasswordFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    requestResetPasswordError: props,
    requestResetPasswordSent: false,
    requestResetPasswordSending: false,
});

const requestResetPasswordSuccessReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    requestResetPasswordError: undefined,
    requestResetPasswordSent: true,
    requestResetPasswordSending: false,
});

const resetPasswordReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    resettingPassword: true,
    resetPasswordError: undefined,
});

const resetPasswordFailReducer = (state: AuthenticationState, props: Error): AuthenticationState => ({
    ...state,
    resettingPassword: false,
    resetPasswordError: props,
});

const resetPasswordSuccessReducer = (state: AuthenticationState): AuthenticationState => ({
    ...state,
    resettingPassword: false,
    resetPasswordError: undefined,
});

const reducer = createReducer(
    initialState,
    on(logIn, logInReducer),
    on(logInFail, logInFailReducer),
    on(logInSuccess, logInSuccessReducer),
    on(logOut, logOutReducer),
    on(requestResetPassword, requestResetPasswordReducer),
    on(requestResetPasswordFail, requestResetPasswordFailReducer),
    on(requestResetPasswordSuccess, requestResetPasswordSuccessReducer),
    on(resetPassword, resetPasswordReducer),
    on(resetPasswordFail, resetPasswordFailReducer),
    on(resetPasswordSuccess, resetPasswordSuccessReducer)
);

export function authenticationReducer(state: AuthenticationState | undefined, action: Action) {
    return reducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { RequestPasswordResetDto } from 'src/app/authentication/types/request-password-reset.dto';
import { ResetPasswordDto } from 'src/app/authentication/types/reset-password.dto';
import { AuthenticationResponse } from '../../types/authentication-response.interface';
import { Credentials } from '../../types/credentials.interface';

export const logIn = createAction('[Authentication] Log In', props<Credentials>());

export const logInFail = createAction('[Authentication] Log In Fail', props<Error>());

export const logInSuccess = createAction('[Authentication] Log In Success', props<AuthenticationResponse>());

export const logOut = createAction('[Authentification] Log Out');

export const requestResetPassword = createAction(
    '[Authentication] Request reset password',
    props<{ payload: RequestPasswordResetDto }>()
);

export const requestResetPasswordFail = createAction('[Authentication] Request reset password failed', props<Error>());

export const requestResetPasswordSuccess = createAction('[Authentication] Request reset password success');

export const resetPassword = createAction('[Authentication] reset password', props<{ payload: ResetPasswordDto }>());

export const resetPasswordFail = createAction('[Authentication] reset password fail', props<Error>());

export const resetPasswordSuccess = createAction(
    '[Authentication] reset password success',
    props<{ payload: AuthenticationResponse }>()
);

export const checkTokenValidity = createAction('[Authentication] Check token validity', props<{ payload: string }>());

export const checkTokenValiditySuccess = createAction('[Authentication] Check token validity success');

export const checkTokenValidityFail = createAction('[Authentication] Check token validity fail', props<{ payload: Error }>());

import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { JwtModule } from '@auth0/angular-jwt';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { authenticationReducer } from './authentication/store/reducers/authentication.reducers';
import { tokenGetter } from './authentication/utils/token-getter';
import { CoreModule } from './core/core.module';
import { appRouterReducer } from './core/store/reducers/router.reducers';

registerLocaleData(localeFr);

/**
 * Synchronize a part of logged user infos to session storage for the need of JWT
 * Keep user infos to session storage so we will no loose it on browser refresh
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{ authentication: ['user', 'userLoggedIn', 'token'] }],
        rehydrate: true, // Allow restoring datas from  storage to store after a browser refresh
        storage: sessionStorage, // Persis inside sessionStorage instead of localStorage (default)
    })(reducer);
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        /**
         * appRouterReducer allow us to have actual route datas (params, query params, url, data, ...) available in our store (router store)
         * localStorageSyncReducer synchronize a part of logged user infos to session storage
         */
        StoreModule.forRoot(appRouterReducer, {
            metaReducers: [localStorageSyncReducer],
            runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true }),
        CoreModule,
        /**
         * Put authentication state in AppModule instead of AuthenticationModule to ensure authentication
         * state rehydratation when we are in another lazzy loaded module and the page is reloaded:
         */
        StoreModule.forFeature('authentication', authenticationReducer),
        /**
         * Every API call to whitelisted domains will automatically have the authorization 'Bearer {token}' in request header.
         * Every API call to blacklisted routes will not have it, this is the case for authentication API route for instance.
         */
        JwtModule.forRoot({
            config: {
                tokenGetter,
                // allowedDomains: ['localhost:3000'],
                allowedDomains: environment.jwtWhitelistedDomains,
                disallowedRoutes: environment.blacklistedRoutes,
            },
        }),
    ],
    declarations: [AppComponent],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}

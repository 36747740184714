import { AuthenticationState } from '../store/reducers/authentication.reducers';

/**
 * Automatically called by JWT mechanism to retrieve token from local storage
 * every time it should be sent in request header as authorization
 */
export function tokenGetter(): string | null {
    const authenticationStateStr = sessionStorage.getItem('authentication');
    const authenticationState: AuthenticationState = authenticationStateStr && JSON.parse(authenticationStateStr);
    return authenticationState?.token;
}
